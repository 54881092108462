/* eslint-disable prettier/prettier */
import * as _ from 'lodash';
import { Button, Input } from 'reactstrap';
import { IClaimNote } from '../../../../../typings';
import { IDatabase, NotesComponentProps } from '../typings';
import { addNote, uploadNotesImage } from '../../../actions/update-claim';
import { arrowRightCircle, image, inbox, loader } from 'react-icons-kit/feather';
import { connect, useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { toast } from 'react-toastify';
import Icon from 'react-icons-kit';
import PerfectScrollbar from 'react-perfect-scrollbar';
import React, { ReactElement, useState } from 'react';
import moment from 'moment';

let scrollRef: HTMLElement;

const initialState: { note: string; loading: boolean, picLoading:boolean } = {
  note: '',
  loading: false,
  picLoading: false
};

const NotesComponent: React.FC<NotesComponentProps> = ({ claim, addNote }) => {
  const [state, setState] = useState(initialState);
  let query: any = [];
  if (claim) {
    query = [
      {
        collection: 'claims',
        doc: claim.id,
        subcollections: [
          {
            collection: 'notes',
            storeAs: 'notes',
            orderBy: ['_audit.created.timestamp', 'asc'],
          },
        ],
        storeAs: 'notes',
      },
    ];
  }

  useFirestoreConnect(query);

  const notes = useSelector(({ firestore: { data } }: any) => {
    return data && data.notes ? data.notes : null;
  });

  const notesLoaded = useSelector(({ firestore: { data } }: any) => {
    return isLoaded(data.notes);
  });

  let userList: Array<string> = [];

  if (notes) {
    query = [
      {
        collection: 'users',
        storeAs: 'users',
      },
    ];
  }

  useFirestoreConnect(query);

  const users = useSelector(({ firestore: { data } }: any) => {
    return data && data.users ? data.users : null;
  });
  const usersLoaded = useSelector(({ firestore: { data } }: any) => {
    return isLoaded(data.users);
  });

  if (notes && usersLoaded) {
    const noteObj = Object.values(notes) as Array<IClaimNote>;
    const userIds = noteObj.map((note: IClaimNote) => {
      if (note && note.watchlist) return note?.watchlist[0];
      else return '';
    });
    userList = userIds.map((userId) => {
      return users[userId]?.email.split('@')[0];
    });
    userList = userList.filter((user) => {
      return user !== undefined;
    });
  }

  const submitNote = () => {
    if (state.note.length > 0) {
      setState({ ...state, loading: true });
      addNote(claim?.id, state.note, "text", (err: any) => {
        setState({ ...state, loading: false });
        if (err) {
          console.error(err);
          toast.error('Could not create note on claim, please try again later');
          return;
        }
        setTimeout(() => {
          scrollRef.scrollTop = scrollRef.scrollHeight * 2;
        }, 100);
        onChangeInput('');
      });
    }
  };

  const submitNoteImage = (note:string) => {
    if (note.length > 0) {
      addNote(claim?.id, note, "image", (err: any) => {
        setState({ ...state, picLoading: false });
        if (err) {
          console.error(err);
          toast.error('Could not create note on claim, please try again later');
          return;
        }
        toast.success('Image uploaded successfully');
        setTimeout(() => {
          scrollRef.scrollTop = scrollRef.scrollHeight * 2;
        }, 100);
        onChangeInput('');
      });
    }
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, picLoading: true });
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        // Remove the data URL prefix
        const base64Data = base64String.split(',')[1];
        // Extract the file extension
        const ext = base64String.substring(base64String.indexOf('/') + 1, base64String.indexOf(';'));
        const files = [base64Data];
        uploadNotesImage(claim?.id as string, base64Data, ext, (err: Error, data?: string) => {
          if (err) {
            console.error(err);
            return toast.error('Error uploading file, please try again later');
          }
          if (data && data.length > 0) {
            submitNoteImage(data);
          }
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const renderNote = (note: IClaimNote & IDatabase, index: string): ReactElement => {
    const rawDate =
      note._audit.created.timestamp && !_.isDate(note._audit.created.timestamp)
        ? note._audit.created.timestamp.toDate()
        : null;
    const bucketId = process.env.NODE_ENV === 'production'
      ? 'glassclaims-production'
      : 'glassclaims-development';
    const fileUrl = `https://storage.googleapis.com/${bucketId}/${note.note}`;
    return (
      <div className="note" key={index}>
        <p className="note-body">
          <p className="note-title">{userList[Number(index)]}</p> 
          {note.noteType === 'image' ? <a href={fileUrl} target="_blank" rel="noopener noreferrer">{note.note}</a> : note.note}
        </p>
        <p className="note-data">
          {rawDate ? moment(rawDate).format('DD MMM, YYYY') : 'null'}
          <br />
          {rawDate ? moment(rawDate).format('HH:MM A') : 'null'}
        </p>
      </div>
    );
  };

  const renderLoadingNotes = (): ReactElement => {
    return (
      <div className="loading-notes">
        <Icon icon={loader} className="spin" size={32} />
        Loading notes...
      </div>
    );
  };

  const renderEmptyNotes = (): ReactElement => {
    return (
      <div className="empty-notes">
        <Icon icon={inbox} size={32} />
        This claim has no active notes
      </div>
    );
  };

  const renderNotes = (): any => {
    if (userList.length > 0) {
      const notesObj = Object.values(notes) as Array<IClaimNote & IDatabase>;
      return notesObj.map((note: IClaimNote & IDatabase, index: number) => {
        return renderNote(note, String(index));
      });
    } else {
      return renderLoadingNotes();
    }
  };

  const onChangeInput = (value: any): void => {
    setState({
      ...state,
      note: value,
    });
  };

  const onNoteKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.nativeEvent.keyCode === 13 && !state.loading && state.note.trim() !== '') {
      e.preventDefault();
      submitNote();
    }
  };

  return (
    <div className="notes">
      <PerfectScrollbar
        className="past-notes"
        containerRef={(ref: HTMLElement) => (scrollRef = ref)}>
        {notesLoaded ? (notes ? renderNotes() : renderEmptyNotes()) : renderLoadingNotes()}
      </PerfectScrollbar>
      <div className="note-input">
        <Input
          type="textarea"
          value={state.note}
          onKeyDown={(e) => onNoteKeyDown(e)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeInput(e.target.value)}
          placeholder='Type your note here or upload jpeg/png'
        />
        <input
          type="file"
          accept="image/*"
          id="image-upload"
          style={{ display: 'none' }}
          onChange={handleImageUpload}
        />
        <Button
          className="upload-image mr-2"
          onClick={() => document.getElementById('image-upload')?.click()}
          disabled={state.picLoading}
          title='Upload image jpeg/png'
        >
          {!state.picLoading ? <Icon icon={image} size={20} /> : <Icon icon={loader} size={20} className="spin" />}
        </Button>
        <Button
          className="submit-note"
          onClick={submitNote}
          disabled={state.loading || state.note === ''}>
          {state.loading ? (
            <Icon icon={loader} size={20} className="spin" />
          ) : (
            <Icon icon={arrowRightCircle} size={20} />
          )}
        </Button>
      </div>
    </div>
  );
};

export default connect(null, { addNote })(NotesComponent);
