/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/named */
import {
  ADD_CLAIM_NOTE,
  ADD_CLAIM_NOTE_FAIL,
  ADD_CLAIM_NOTE_IN_PROGRESS,
  UPDATE_CLAIM,
  UPDATE_CLAIM_FAIL,
  UPDATE_CLAIM_IN_PROGRESS,
  UPLOAD_INVOICE,
  UPLOAD_INVOICE_FAIL,
  UPLOAD_INVOICE_IN_PROGRESS,
} from './types';
import { ActionCreator } from 'redux';
import { INewClaimPayload } from '../../../../typings';
import { functions } from '../../config/firebase';
import _ from 'lodash';
import moment from 'moment';

export const completeStep = (
  id: string,
  step: string,
  payload: any,
  callback: Function
) => {
  return (dispatch: any) => {
    const values: any = {};
    if (payload && !_.isEmpty(payload)) {
      _.each(payload, (value) => {
        values[value.key] = moment.isMoment(value.value)
          ? value.value.toDate().toISOString()
          : value;
      });
    }
    dispatch({ type: UPDATE_CLAIM_IN_PROGRESS });
    const callable = functions.httpsCallable('completeStep');
    callable({ id, step, values })
      .then(() => {
        dispatch({ type: UPDATE_CLAIM, payload: { id, step } });
        callback(null);
      })
      .catch((err) => {
        dispatch({ type: UPDATE_CLAIM_FAIL, payload: { id, step } });
        callback(err);
      });
  };
};

export const serviceDoneStep = (
  id: string,
  step: string,
  payload: any,
  callback: Function
) => {
  return (dispatch: any) => {
    const values: any = {};
    if (payload && !_.isEmpty(payload)) {
      _.each(payload, (value) => {
        values[value.key] = moment.isMoment(value.value)
          ? value.value.toDate().toISOString()
          : value;
      });
    }
    dispatch({ type: UPDATE_CLAIM_IN_PROGRESS });
    const callable = functions.httpsCallable('serviceDoneStep');
    callable({ id, step, values })
      .then(() => {
        dispatch({ type: UPDATE_CLAIM, payload: { id, step } });
        callback(null);
      })
      .catch((err) => {
        dispatch({ type: UPDATE_CLAIM_FAIL, payload: { id, step } });
        callback(err);
      });
  };
};

export const invoiceStep = (
  id: string,
  step: string,
  payload: any,
  callback: Function
) => {
  return (dispatch: any) => {
    const values: any = {};
    if (payload && !_.isEmpty(payload)) {
      _.each(payload, (value) => {
        values[value.key] = moment.isMoment(value.value)
          ? value.value.toDate().toISOString()
          : value;
      });
    }
    dispatch({ type: UPDATE_CLAIM_IN_PROGRESS });
    const callable = functions.httpsCallable('invoiceStep');
    callable({ id, step, values })
      .then(() => {
        dispatch({ type: UPDATE_CLAIM, payload: { id, step } });
        callback(null);
      })
      .catch((err) => {
        dispatch({ type: UPDATE_CLAIM_FAIL, payload: { id, step } });
        callback(err);
      });
  };
};

export const updateStep = (
  id: string,
  step: string,
  reason: any,
  callback: Function
) => {
  const callable = functions.httpsCallable('updateTodoStep');
  callable({ id, step, reason })
    .then(() => {
      callback(null);
    })
    .catch((err) => {
      callback(err);
    });
};

export const uploadFile = (
  id: string,
  payload: { files: any[]; key: string },
  callback: Function
) => {
   console.log('tttt', id, payload);
  return (dispatch: any) => {
    dispatch({ type: UPLOAD_INVOICE_IN_PROGRESS });
    const callable = functions.httpsCallable('uploadFiles');
    callable({ id, files: payload.files, key: payload.key })
      .then(() => {
        console.log('succsess', id, payload);
        dispatch({ type: UPLOAD_INVOICE, payload: { id } });
        callback(null);
      })
      .catch((err) => {
        console.log('error', err);
        dispatch({ type: UPLOAD_INVOICE_FAIL, payload: { id } });
        callback(err);
      });
  };
};

export const uploadNotesImage = (
  id: string,
  base64File: string,
  ext: string,
  callback: Function
) => {
  // return async (dispatch: any) => {
    // dispatch({ type: UPLOAD_INVOICE_IN_PROGRESS });
    try {
      console.log('inside try', id, base64File);
      const uploadNotesImageCallable = functions.httpsCallable('uploadNotesImage');
      uploadNotesImageCallable({ id, files: base64File, ext })
        .then((result) => {
          console.log('inside after', id, base64File);
          // Handle the returned value
          console.log('Uploaded image URL:', result.data.name);
          callback(null, result.data.name);
        })
        .catch((err) => {
          console.error('Error calling uploadNotesImage:', err);
          callback(err);
        });
    } catch (err) {
      console.error('Error calling uploadNotesImage:', err);
      // dispatch({ type: UPLOAD_INVOICE_FAIL, payload: { id } });
      callback(err);
    }
  // };
};

export const addNote = (id: string, note: string, noteType:string, callback: Function) => {
  return (dispatch: any) => {
    dispatch({ type: ADD_CLAIM_NOTE_IN_PROGRESS });
    const callable = functions.httpsCallable('addNote');
    callable({ id, note, noteType })
      .then(() => {
        dispatch({ type: ADD_CLAIM_NOTE, payload: { id } });
        callback(null);
      })
      .catch((err) => {
        dispatch({ type: ADD_CLAIM_NOTE_FAIL, payload: { id } });
        callback(err);
      });
  };
};

export const updateClaim = (
  id: string,
  claim: INewClaimPayload,
  currencyChangeObj: any,
  callback: Function
) => {
  return (dispatch: any) => {
    dispatch({ type: UPDATE_CLAIM_IN_PROGRESS });
    const callable = functions.httpsCallable('updateClaim');
    callable({ id, claim, currencyChangeObj })
      .then(() => {
        dispatch({ type: UPDATE_CLAIM, payload: { id } });
        callback(null);
      })
      .catch((err) => {
        dispatch({ type: UPDATE_CLAIM_FAIL, payload: { id } });
        callback(err);
      });
  };
};

export const acceptIncomingClaim = (id: string, callback: Function) => {
  return (dispatch: any) => {
    const callable = functions.httpsCallable('acceptIncomingClaim');
    callable({ id })
      .then(() => {
        callback(null);
      })
      .catch((err) => {
        callback(err);
      });
  };
};

export const slaDoneMessageCheck = (id: string, callback: Function) => {
  return (dispatch: any) => {
    const callable = functions.httpsCallable('slaDoneMessageCheck');
    callable({ id })
      .then(() => {
        callback(null);
      })
      .catch((err) => {
        callback(err);
      });
  };
};

export const rejectIncomingClaim = (id: string, callback: Function) => {
  return (dispatch: any) => {
    const callable = functions.httpsCallable('rejectIncomingClaim');
    callable({ id })
      .then(() => {
        callback(null);
      })
      .catch((err) => {
        callback(err);
      });
  };
};

export const submitRatingForClaim = (
  id: string,
  rating: number,
  reason: string = '',
  callback: Function
) => {
  return (dispatch: any) => {
    const callable = functions.httpsCallable('submitRatingForClaim');
    callable({ id, rating, reason })
      .then((data) => {
        callback(data.data);
      })
      .catch((err) => {
        callback(err);
      });
  };
};

export const cancelClaim = (
  id: string,
  reason: string,
  cancelledBy: string,
  callback: Function
) => {
  return (dispatch: any) => {
    const callable = functions.httpsCallable('cancelClaim');
    callable({ id, reason, cancelledBy })
      .then(() => {
        callback(null);
      })
      .catch((err) => {
        callback(err);
      });
  };
};

export const downloadClaimInfo: ActionCreator<any> = async (
  id: string,
  isCompleteInfo: boolean
) => {
  try {
    const downloadClaimInfo = functions.httpsCallable('downloadClaimInfo');
    const pdf = await downloadClaimInfo({ id, isCompleteInfo });
    return pdf.data;
  } catch (e) {
    console.error(e);
  }
};

export const downloadClaimDetail = async () => {
  try {
    const downloadClaimDetail = functions.httpsCallable("downloadClaimDetail");
    const csv = await downloadClaimDetail();
    return Object.values(csv);
  } catch (e) {
    console.error(e);
  }
};

export const downloadClaimDetailCsvNew = async () => {
  try {
    const downloadClaimDetailCsvNew = functions.httpsCallable("downloadClaimDetailCsvNew");
    const csv = await downloadClaimDetailCsvNew();
    return Object.values(csv);
  } catch (e) {
    console.error(e);
  }
};

export const downloadMemberDetailCsv = async () => {
  try {
    const downloadMemberDetailCsv = functions.httpsCallable("downloadMemberDetailCsv");
    const csv = await downloadMemberDetailCsv();
    return Object.values(csv);
  } catch (e) {
    console.error(e);
  }
};

export const deactivateNewsFeed = (
  id: string,
  callback: Function
) => {
  return (dispatch: any) => {
    dispatch({ type: UPDATE_CLAIM_IN_PROGRESS });
    const callable = functions.httpsCallable('deactivateNewsFeed');
    callable({ id })
      .then(() => {
        dispatch({ type: UPDATE_CLAIM, payload: { id } });
        callback(null);
      })
      .catch((err) => {
        dispatch({ type: UPDATE_CLAIM_FAIL, payload: { id } });
        callback(err);
      });
  };
};