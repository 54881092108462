/* eslint-disable prettier/prettier */
import * as _ from 'lodash';
import { Button, ModalBody, ModalHeader } from 'reactstrap';
import { FilesViewModalProps } from '../claims/new/typings';
import { Icon } from 'react-icons-kit';
import { connect } from 'react-redux';
import { downloadFiles } from '../../actions/files';
import { eye, loader, x } from 'react-icons-kit/feather';
import { toast } from 'react-toastify';
import ImageGallery  from 'react-image-gallery';
import Modal from 'react-modal';
import React, { useState } from 'react';

const initialImages: any[] = [];

const FilesViewModal: React.FC<FilesViewModalProps> = ({
  isOpen,
  files,
  onClose,
  downloadFiles,
}) => {
  const fileKeys = files ? Object.keys(files) : [];
  const [loading, setLoading] = useState(new Map());
  const [images, setImages] = useState(initialImages);
  const [isOpenModal, toggleModal] = useState(false);

  const getDisplayKey = (keyIn: string) => {
    switch (keyIn) {
      case 'invoice':
        return 'Invoice -';
      case 'vehicleImages':
        return 'Vehicle Images -';
      case 'calibrationCertificate':
        return 'Calibration Certificate -';
      case 'attachments':
        return 'Attachments -';
      case 'proofOfPayment':
        return 'Proof of Payment -';
      default:
        break;
    }
  };

  const openFile = (pdf: string, mimeType: string) => {
    // base64 string

    // const base64str = await toBase64(pdf);

    const binaryString = atob(pdf);

    const binaryLen = binaryString.length;

    const bytes = new Uint8Array(binaryLen);

    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }

    // create the blob object with content-type "application/pdf"
    const blob = new Blob([bytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    window.open(url);
  };

  const openPdf = async (files: {
    [index: number]: { data: any; mimeType: string };
  }): Promise<void> => {
    _.each(files, (file) => {
      openFile(file.data, file.mimeType);
    });
  };

  const openImageModal = (files: { data: string; mimeType: string }[]) => {
    const imgs: any[] = [];
    _.each(files, ({ data: file, mimeType }) => {
      imgs.push({
        original: `${mimeType},${file}`,
        thumbnail: `${mimeType},${file}`,
      });
    });
    setImages(imgs);
    toggleModal(true);
  };

  const download = (file: Array<{ fileName: string; mimeType: string }>, key: string) => {
    setLoading(_.cloneDeep(loading.set(file[0].fileName, true)));
    downloadFiles(file, (err: Error, files: any) => {
      setLoading(_.cloneDeep(loading.set(file[0].fileName, false)));
      if (err) {
        console.error(err);
        toast.error(`Could not retrieve ${key}, please try again later.`);
        return;
      }
      if (!files[0].mimeType.includes('pdf')) {
        openImageModal(files);
      } else {
        openPdf(files);
      }
    });
  };

  return (
    <div>
      <Modal isOpen={isOpen} className="files-modal" ariaHideApp={false}>
        <ModalHeader>
          Files Attached{' '}
          <Button className="close-btn" onClick={onClose}>
            <Icon icon={x} size={28} />
          </Button>
        </ModalHeader>
        <ModalBody>
          {fileKeys.map((key) => {
            return (
              <div key={key}>
                <h5 className="file-types-header">{getDisplayKey(key)}</h5>
                {files[key].map((file) => {
                  return (
                    <div key={file.fileName} className="files-attached-div">
                      <h6>{file.fileName}</h6>
                      <Button
                        className="file-single"
                        color="link"
                        onClick={() => download([file], key)}
                        size="sm">
                        {loading.get(file.fileName) ? (
                          <Icon icon={loader} size={16} className="spin" />
                        ) : (
                          <Icon icon={eye} />
                        )}
                      </Button>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </ModalBody>
      </Modal>
      <Modal isOpen={isOpenModal} className="image-modal" ariaHideApp={true}>
        <ImageGallery items={images} />
        <Button className="image-modal-close" onClick={() => toggleModal(!isOpenModal)}>
          <Icon icon={x} size={64} />
        </Button>
      </Modal>
    </div>
  );
};

export default connect(null, { downloadFiles })(FilesViewModal);
