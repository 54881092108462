/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { INewNewsFeedPayload } from '../../../../../typings';
import { NewsFeedContext } from '../newsFeedContext';
import { NewsFormProps } from '../../claims/new/typings';
import { alpha } from '@mui/material/styles';
import { connect } from 'react-redux';
import { deactivateNewsFeed } from '../../../../src/actions/update-claim';
import { getNewsFeed, submitNewNewsFeed } from '../../../../src/actions/new-claim';
import { toast } from 'react-toastify';
import Checkbox from '@mui/material/Checkbox';
import React, { createRef, useContext, useEffect, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import history from '../../../helpers/history';

const initialState: { loading: boolean } = {
  loading: false,
};

const PostInput: React.FC<NewsFormProps> = ({
  id,
  newNewsFeed,
  updateNewNewsFeedValue,
  submitNewNewsFeed,
  resetNewsFeed,
  getNewsFeed,
  deactivateNewsFeed,
}) => {
  const { feedDispatch } = useContext(NewsFeedContext);
  const inputRef = createRef<HTMLFormElement>();

  const [state, setState] = useState(initialState);
  const [data, setData] = useState([]);

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('title');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [dense, setDense] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(2);

  let rows = data;

  useEffect(() => {
    getNewsFeeds();
  }, []);

  const addPost = async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      content: [{ value: string }];
    };

    let newsTitle = '';
    let newsDescription = '';
    if (
      target.content[0].value !== '' &&
      target.content[0].value !== null &&
      target.content[0].value !== undefined
    ) {
      newsTitle = target.content[0].value;
      console.log(newsTitle);
    } else {
      toast.error('Could not create news feed. Please try again later');
    }

    if (
      target.content[1].value !== '' &&
      target.content[1].value !== null &&
      target.content[1].value !== undefined &&
      target.content !== undefined
    ) {
      newsDescription = target.content[1].value;
      console.log(newsDescription);
    } else {
      toast.error('Could not create news feed. Please try again later');
    }

    const newsDetails: INewNewsFeedPayload = {
      news: {
        title: newsTitle,
        news: newsDescription,
      },
    };
    setState({ loading: true });
    submitNewNewsFeed(newsDetails, (err: Error) => {
      setState({ loading: false });
      if (err) {
        console.error(err);
        toast.error('Could not create news feed. Please try again later');
        return false;
      }
      inputRef.current?.reset();
      history.push('/');
      return true;
    });
  };

  const getNewsFeeds = async () => {
    try {
      let resp;
      await getNewsFeed().then((response: any) => {
        resp = response;
      });
      setData(resp.newsFeedData.data);
      rows = resp.newsFeedData.data;
      return rows;
      // console.log(resp);
    } catch (e) {
      console.error(e);
    }
  };

  interface Data {
    title: string;
    news: string;
    createdBy: string;
  }

  type Order = 'asc' | 'desc';

  interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'title',
      numeric: false,
      disablePadding: true,
      label: 'Title',
    },
    {
      id: 'news',
      numeric: false,
      disablePadding: true,
      label: 'News',
    },
    {
      id: 'createdBy',
      numeric: false,
      disablePadding: true,
      label: 'Created By',
    },
  ];

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.news.title);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
  }

  function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
  ): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            {/* <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            /> */}
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}>
              <TableSortLabel>
                {/* // active={orderBy === headCell.id}
                // direction={orderBy === headCell.id ? order : 'asc'}
                // onClick={createSortHandler(headCell.id)}> */}
                {headCell.label}
                {/* {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null} */}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  interface EnhancedTableToolbarProps {
    numSelected: number;
  }

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const saveDeactivate = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const id = event.target.value;
    deactivateNewsFeed(id, (err: Error) => {
      setState({ loading: false });
      if (err) {
        console.error(err);
        toast.error('Could not create claim. Please try again later');
        return false;
      }
      history.push('/');
      return true;
    });
  };

  function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected } = props;

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}>
        {numSelected > 0 ? (
          <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
            News Feeds
          </Typography>
        )}
      </Toolbar>
    );
  }

  const visibleRows = React.useMemo(
    () =>
      stableSort(data, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [data, getComparator, order, orderBy, page, rowsPerPage],
  );

  return (
    <>
      <Box sx={{ width: '100%', overflowX: 'auto' }}>
        <form ref={inputRef} onSubmit={addPost}>
          <TextField
            multiline={false}
            variant="outlined"
            name="content"
            placeholder="Enter Title Here..."
            rows={4}
            fullWidth
          />
          &nbsp;
          <TextField
            multiline
            variant="outlined"
            name="content"
            placeholder="Enter Message Here..."
            rows={4}
            fullWidth
          />
          <Box mt={1} display="flex" justifyContent="flex-end">
            <Button color="primary" type="reset">
              Reset
            </Button>
            <Button color="primary" variant="contained" type="submit" disableElevation>
              Publish
            </Button>
          </Box>
        </form>
      </Box>
      &nbsp; &nbsp;
      <Box
        sx={{
          width: '200%',
          // scrollbarWidth: '25%',
          // scrollMargin: '25%',
          marginLeft: '-240px',
          // maxHeight: '25%',
          overflowX: 'auto',
          marginBottom: '-240px',

        }}>
        <Paper sx={{ width: '100%', mb: 1 }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer component={Paper} sx={{ maxHeight: '200px', overflowY: 'auto' }}>
          <Table stickyHeader aria-label="scrollable table">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row[0].news.title);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      style={{ height: 15 }}
                      onClick={(event) => handleClick(event, row[0].news.title)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row[0].news.title}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row[0].news.title}
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row[0].news.news}
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row[0].origin}
                      </TableCell>
                      <TableCell hidden align="left">
                        {row[1]}
                      </TableCell>
                      <TableCell align="left">
                        <Button
                          variant="contained"
                          endIcon={<SendIcon />}
                          size="small"
                          value={row[1]}
                          disabled={!isItemSelected}
                          onClick={(event) => saveDeactivate(event, row[1])}>
                          De-activate
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[2]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
};

export default connect(null, { submitNewNewsFeed, getNewsFeed, deactivateNewsFeed })(PostInput);
