/* eslint-disable prettier/prettier */
/* eslint-disable import/named */
import { Button, ModalBody, ModalHeader } from 'reactstrap';
import { ConfirmModalProps, DropdownOption } from '../claims/new/typings';
import { Icon } from 'react-icons-kit';
import { alertTriangle } from 'react-icons-kit/feather';
import Modal from 'react-modal';
import React, { useState } from 'react';
import Select, { ValueType } from 'react-select';

const reasons = [
  "The customer didn't answer. Even though we tried to contact them several times.",
  'Customer went to another competitor.',
  'Automotive Glass not available (Stock Break)',
  "Price isn't suitable for the customer.",
  'Time of Service.',
  "Customer couldn't wait and continued travelling.",
  'Inadequate Communication with customer',
  "The member doesn't respond or responds too late and the customer wants to cancel",
  'Personal Preference of the customer',
];

const CancelModal: React.FC<ConfirmModalProps> = ({
  claim,
  isOpen,
  onConfirm,
  onCancel,
  reason,
  onChangeReason,
}) => {
  const [showWarning, setShowWarning] = useState(false);

  const options = reasons.map((currency: string) => {
    return {
      value: currency,
      label: currency,
    };
  });

  return (
    <div>
      <Modal isOpen={isOpen} className="cancel-claim-modal" ariaHideApp={false}>
        <ModalHeader>Claim Cancellation</ModalHeader>
        <ModalBody>
          <p>
            Cancelling this claim will remove all associated data for claim{' '}
            <strong>{claim?.insurer?.claimNumber}</strong> for the driver{' '}
            <strong>
              {claim?.driver?.name} {claim?.driver?.surname}. {claim?.assign?.partner}{' '}
            </strong>
            will be sent an email about the cancellation of the claim with the reason stated below.
          </p>
          <p>
            While the client information will remain in the system, a cancelled claim is
            <strong> not recoverable.</strong>
          </p>
          <p>Are you sure you wish to cancel this claim?</p>
          <Select
            className="select-dropdown"
            menuPlacement='top'
            options={options}
            value={{ label: reason || '', value: reason || '' }}
            onChange={(selectedOption: ValueType<DropdownOption>) => {
              setShowWarning(false);
              if (typeof onChangeReason === 'function') {
                const selectedValue: string = (selectedOption as unknown as DropdownOption).value;
                return onChangeReason(selectedValue as string);
              }
            }}
          />
          {showWarning && (
            <>
              <br />
              <div style={{ margin: 0 }} className="info-div">
                <Icon icon={alertTriangle} tag="div" className="info-icon" />
                <div>Please enter a valid reason to cancel the claim</div>
              </div>
            </>
          )}
          <br />
          <div className="cancel-modal-buttons">
            <Button size="sm" color="secondary" block onClick={() => onCancel()}>
              No, Return To Claim
            </Button>
            <Button
              size="sm"
              color="danger"
              block
              outline
              onClick={() => {
                if (reason !== '') {
                  onConfirm();
                } else {
                  setShowWarning(true);
                }
              }}>
              <Icon icon={alertTriangle} tag="div" className="btn-icon" /> Yes, Cancel Claim
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CancelModal;
